import { Component, Vue } from "vue-property-decorator";
import { GET_MAINTENANCE, SAVE_MAINTENANCE } from "../../../api/account"

import DatePicker from 'vue2-datepicker';

import 'vue2-datepicker/index.css';

@Component({
    components: {
        DatePicker
    },
    filters: {
    }
})

class Maintenance extends Vue {

    // public canDisplayUatMaintenanceButton = process.env.NODE_ENV == 'development'
    
    public dataStore = {
        // server: '',
        status: false,
        maintenanceDateTime: new Date(),
        descriptionTH: "",
        descriptionEN: ""
    }

    private styleLoader = {
        color: '#CFA137',
        loader: 'spinner',
        width: 145,
        height: 235,
        backgroundColor: '#000000',
        opacity: 0.5,
    }

    async mounted() {
        const loader = this.$loading.show(this.styleLoader)
        await this.getLastMaintenance()
        loader.hide()
    }

    public async getLastMaintenance(){
        const maintenanceResult = await GET_MAINTENANCE()
        if(maintenanceResult.data){
            this.dataStore.status = maintenanceResult.data.status
            this.dataStore.descriptionTH = maintenanceResult.data.descriptionTH
            this.dataStore.descriptionEN = maintenanceResult.data.descriptionEN
            this.dataStore.maintenanceDateTime = new Date(maintenanceResult.data.updatedDate)
        }
    }

    public setMaintenanceVal( status: boolean): void{
        this.dataStore.status = status
    }
    
    //server: string
    public async saveMaintenance(): Promise<void>{
        // this.dataStore.server = server
        const confirmationResult = await this.$swal({
            title: `${this.$t('MANAGEMENT.WANT_ADD_DATA').toString()}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#CFA137',
            confirmButtonText: `<span style='color: white;'>${this.$t('CONFIRM').toString()}</span>`,
            cancelButtonColor: '#FF0000',
            cancelButtonText: `<span style='color: white;'>${this.$t('CANCEL').toString()}</span>`,
        })

        if (!confirmationResult.isConfirmed) {
            return
        }

        const loader = this.$loading.show(this.styleLoader)
        const resultSaveMaintenance = await SAVE_MAINTENANCE(this.dataStore)
        if (!resultSaveMaintenance.success) {
            this.$swal({
                text: resultSaveMaintenance.error.message,
                icon: "error",
                confirmButtonColor: '#CFA137',
                confirmButtonText: `<span style='color: white;'>${this.$t('OK').toString()}</span>`
            });
            loader.hide()
            return
        }
        this.$swal({
            text: 'save success',
            icon: "success",
            timer: 3000,
            showConfirmButton: false,
            timerProgressBar: true,
        });
        loader.hide()
        
    }
}
export default Maintenance