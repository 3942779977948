


























































































































import { Component, Mixins } from "vue-property-decorator";
import Maintenance from "./Maintenance.component";

@Component
export default class Retry extends Mixins(Maintenance) {}
